import { Pipe, PipeTransform } from '@angular/core';

import { SearchResultAllInputs } from '../types/search-result-all.type';

@Pipe({
  name: 'allFindings',
  standalone: true,
})
export class SearchResultAllPipe implements PipeTransform {
  transform(value: SearchResultAllInputs[]): number {
    return value?.length
      ? value.reduce(
          (counter, item) =>
            counter + (item?.sights?.length || 0) + (item?.news?.length || 0) + (item?.faq?.length || 0) + (item?.programs?.length || 0),
          0
        )
      : 0;
  }
}
